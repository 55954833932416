<template>
  <div v-click-out="() => onBlured()" class="place-items-center p-password-input">
    <div class="p-password-input__wrapper">
      <p-text-input
        v-model="password"
        v-click-out="() => onBlured()"
        :name="name"
        :type="isEyeOpen ? 'text' : 'password'"
        :label="label"
        @focus="onFocused()"
      >
        <template #append>
          <p-icon
            v-if="password || isFocused"
            color="foreground-secondary"
            size="xmd"
            :icon="isEyeOpen ? 'eye-open-regular' : 'eye-close-solid'"
            @on-click="isEyeOpen = !isEyeOpen"
          />
        </template>
      </p-text-input>
    </div>

    <div v-if="password" class="p-password-input__rules">
      <div
        v-for="({ textAttributes, iconAttributes }, index) in rules"
        :key="`rule-${index}`"
        class="p-password-input__rules--item"
      >
        <div :severity="iconAttributes.severity" class="p-password-input__text">
          <p-icon
            :icon="iconAttributes.icon"
            :size="iconAttributes.size"
            color="foreground-fix-white"
          />
          <span v-text="textAttributes.value" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const isEyeOpen = ref(false)
const isFocused = ref(false)
const isBlured = ref(false)

defineProps({
  name: {
    type: String,
    default: null,
  },
  label: {
    type: String,
    required: true,
  },
})

defineEmits(['on-input'])

const password = defineModel<string>('modelValue', { default: '' })

const onFocused = () => {
  isFocused.value = true
  isBlured.value = false
}

const onBlured = () => {
  isBlured.value = true
  isFocused.value = false
}

const hasUpperCase = computed(() => {
  return password.value && /[A-Z]/.test(password.value) ? 'success' : 'info'
})

const hasLowerCase = computed(() => {
  return password.value && /[a-z]/.test(password.value) ? 'success' : 'info'
})

const hasNumber = computed(() => {
  return password.value && /[0-9]/.test(password.value) ? 'success' : 'info'
})

const hasExpectedLength = computed(() => {
  return password.value && password.value?.length >= 8 ? 'success' : 'info'
})

const rules = computed(() => {
  const iconBox: Record<string, { icon: string; severity: string; size: string }> = {
    success: {
      icon: 'check',
      size: 'xs',
      severity: 'success',
    },
    info: { icon: 'check', size: 'xs', severity: 'disabled' },
  }

  return [
    {
      textAttributes: {
        value: $t('validation.password.upparCase'),
      },
      iconAttributes: iconBox[hasUpperCase.value],
    },
    {
      textAttributes: {
        value: $t('validation.password.lowerCase'),
      },
      iconAttributes: iconBox[hasLowerCase.value],
    },
    {
      textAttributes: {
        value: $t('validation.password.number'),
      },
      iconAttributes: iconBox[hasNumber.value],
    },
    {
      textAttributes: {
        value: $t('validation.password.minLength'),
      },
      iconAttributes: iconBox[hasExpectedLength.value],
    },
  ]
})
</script>
